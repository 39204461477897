@import "css/_mixins.scss";

@font-face {
  font-family: 'Montserrat' !important;
  src: url('../assets/font/Montserrat-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'arial' !important;
  src: url('../assets/font/arial.ttf') format('ttf');
}
@font-face {
  font-family: 'TimeNewsRoman' !important;
  src: url('../assets/font/Time-News-Roman.ttf') format('ttf');
}
@font-face {
  font-family: 'sans-serif' !important;
  src: url('../assets/font/sans-serif.ttf') format('ttf');
}
@font-face {
  font-family: 'serifa' !important;
  src: url('../assets/font/serifa.ttf') format('ttf');
}
@font-face {
  font-family: 'times' !important;
  src: url('../assets/font/times.ttf') format('ttf');
}

@font-face {
  font-family: 'roboto' !important;
  src: url('../assets/font/roboto.ttf') format('ttf');
}

.modal-content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.hide-radio {
  .ant-radio {
    display: none;
  }
  .ant-radio-wrapper {
    margin-right: 0;
  }
  .ant-radio-group {
    display: flex;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

button:disabled {
  cursor: not-allowed;
}

button:focus {
  outline: none !important;
}

ul, li {
	list-style-type: none;
	padding-left: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.btn-primary {
  background-color: #579bd7;
  padding: 8px 25px;
  border-radius: 15px;
  font-size: 14px;
  color: #fff;
  border: none;
  outline: none;
  font-weight: 600;
  &:hover, &:focus, &:active {
    text-decoration: none;
    background-color: #3eb7e4;
    border: none;
    outline: none;
  }
}

.image-hover {
  overflow: hidden;
  div, div:hover, a, a:hover {
    overflow: hidden;
    border-radius: 10px;
  }
}

.image-hover img,
.image-hover .thumb-title {
	transform: scale(1);
	transition: .4s ease-in-out;
}

.image-hover:hover img {
  transform: scale(1.2);
}

.image-hover:hover .thumb-title {
  background: #0a62fb;
}

.btn-hover {
  @include transition(all 0.5s);
  color: rgba(255, 255, 255, 1);
  position: relative;
  &:focus {
    outline: none;
  }
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: rgba(255,255,255,0.4);
    @include transition(all 0.3s);
  }
  &:hover::before{
    opacity: 1;
    width: 100%;
  }
}

.btn-hover2 {
  &:hover {
    transition: all 0.25s;
    box-shadow: 0 0.5em 0.5em -0.4em #3AA4F6;
    transform: translateY(-0.25em);
  }
}

@mixin border-gradient($from, $to, $weight: 0) {
  $mix-main: mix($from, $to);
  $mix-sub-from: mix($mix-main, $from);
  $mix-sub-to: mix($mix-main, $to);
  
  box-shadow: 0 1px 0 $weight rgba($mix-sub-to, .25),
    0 -1px 0 $weight rgba($mix-sub-from, .25),
    1px 0 0 $weight rgba($mix-sub-to, .25),
    -1px 0 0 $weight  rgba($mix-sub-from, .25),
    1px -1px 0 $weight rgba($mix-main, .5),
    -1px 1px 0 $weight rgba($mix-main, .5),
    1px 1px 0 $weight rgba($to, .75),
    -1px -1px 0 $weight rgba($from, .75);
}

.border-grad {
  border-width: 2px;
  @include border-gradient(skyblue, hotpink);
}

.question-star {
  .ant-form-item-control-input-content {
    text-align: right;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-modal-title {
  font-size: 22px !important;
}

.ant-modal-body {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.ant-modal-footer {
  text-align: center !important;
  border-top: 1px solid #ddd;;
  background-color: #f5f5f5 !important;
  button {
    border-radius: 4px;
  }
}

.is-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.9;
}

// Hide Arrows From Input Number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.err-msg {
  color: red;
  margin-top: 5px;
  font-size: 12px;
  text-align: right;
  min-height: 18px;
}

.modal-backdrop {
  z-index: 100 !important;
}

.ant-notification {
  z-index: 200;
}

.pagination-container {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 0em;
}

.footer-license {
  background-color: #0284c7;
}
