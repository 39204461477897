.header-container {
  background-color: #f0f9ff;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  .header-img {
    max-width: 984px;
    @media screen and (max-width: 767px) {
      border-radius: 0;
    }
  }
}
