.input-text {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  input {
    width: 100%;
    border: 1px solid #c5c5c5;
    box-sizing: border-box;
    line-height: 20px;
    padding: 7px 13px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    outline: none;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    height: 50%;
    cursor: pointer;
  }
  &__title {
    width: 60%;
  }
}

.input-common {
  // .custom-input {
  //   border: none;
  //   border-bottom: 1px solid #C5C5C5;
  //   position: relative;
  //   padding-left: 16px;
  //   padding-top: 16px;
  //   font-size: 16px;
  //   &:focus {
  //     outline: none;
  //     box-shadow: none !important;
  //   }
  //   &:focus + span,
  //   &:not(:placeholder-shown) + span{
  //     top: 0;
  //     transform: unset;
  //     left: 16px;
  //     font-size: 12px;
  //     color: #3aa4f6;
  //   }
  // }
  &__placeholder {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 48%;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 24px;
    color: #7c808b;
    transition: 0.3s ease all;
  }
  &__help-text {
    pointer-events: none;
    color: #a0b4b6;
    font-size: 13px;
  }
}

.input-container {
  position: relative;
}

.custom-input {
  display: block;
  width: 100%;
  padding: 24px 8px 8px 8px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #f1f5f9;
  outline: none;
  background-color: transparent;
  &:focus {
    outline: none;
    border: transparent;
    box-shadow: none !important;
  }
  &:hover {
    border-bottom: 2px solid #f1f5f9;
    box-shadow: none !important;
  }
  &:focus + .input-common__placeholder,
  &:not(:placeholder-shown) + .input-common__placeholder {
    top: 0;
    transform: unset;
    left: 8px;
    font-size: 12px;
    color: #3aa4f6;
  }
}

.input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #0ea5e9;
  transition: all 0.5s ease;
}

.custom-input:focus ~ .input-highlight {
  width: 100%;
}
