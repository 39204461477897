.progress-turn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.4);
  .ant-spin-lg .ant-spin-dot {
    font-size: 45px;
  }
  .ant-spin-lg .ant-spin-dot i {
    width: 18px;
    height: 18px;
  }
}